export type Point2D = { x: number; y: number };
/**
 * @module CoordinateSystems
 * Offers functions to convert between various coordinate systems used in the application.
 * These include the following:
 * -  Normalized Device Coordinates (ndc): A coordinate system where 0,0 represents the top left of
 *    the screen and 1,1 the bottom right
 * -  Centered Normalized Device Coordinates (cndc): A coordinate system where 0,0 represents the center of
 *    the screen, -1,-1 the top left and 1,1 the bottom right
 * -  SVG coordinates: Coordinates in the space of an SVG image. In our application they are similar to CNDC,
 *    but in a square area, meaning coordinate extremes can leave the [-1, 1] interval
 * -  Document coordinates: Coordinates relative to an HTML element in pixels
 */

/**
 * Converts document coordinates to SVG coordinates
 * WARNING: getScreenCTM returns wrong values if the SVGs parent has been transformed
 * via css in Firefox. This is a known issue.
 * @param documentCoordinates x and y in document space
 * @param svg the SVG element used as the target coordinate system for the conversion
 * @param relativeTo the element the ndc is relative to
 * @returns x and y coordinates in the SVGs coordinate space
 */
export const documentToSvg = (
  documentCoordinates: { x: number; y: number },
  svg: SVGSVGElement
): { x: number; y: number } => {
  const ctm = svg.getScreenCTM();
  if (ctm) {
    const pt = svg.createSVGPoint();
    pt.x = documentCoordinates.x;
    pt.y = documentCoordinates.y;
    const svgCoordinates = pt.matrixTransform(ctm.inverse());
    return { x: svgCoordinates.x, y: svgCoordinates.y };
  }
  return { x: 0, y: 0 };
};

/**
 * Inverse of documentToSvg
 * @param svgCoordinates x and y coordinates in svg space
 * @param svg the svg element providing the conversion matrix
 * @returns x and y coordinates  in document space
 */
export const svgToDocument = (
  svgCoordinates: { x: number; y: number },
  svg: SVGSVGElement
): { x: number; y: number } => {
  const ctm = svg.getScreenCTM();
  if (ctm) {
    const pt = svg.createSVGPoint();
    pt.x = svgCoordinates.x;
    pt.y = svgCoordinates.y;
    const documentCoordinates = pt.matrixTransform(ctm);
    return { x: documentCoordinates.x, y: documentCoordinates.y };
  }
  return { x: 0, y: 0 };
};

/**
 * Converts normalized device coordinates to document coordinates
 * @param ndc x and y coordinates in ndc space
 * @param relativeTo the HTML Element the coordinates are relative to, defaults to document.body
 * @returns x and y coordinates in document space
 */
export const ndcToDocument = (
  ndc: { x: number; y: number },
  relativeTo?: HTMLElement | SVGGraphicsElement
): { x: number; y: number } => {
  if (!relativeTo) relativeTo = document.body;
  const boundingClientRect = relativeTo.getBoundingClientRect();
  return {
    x: ndc.x * boundingClientRect.width + boundingClientRect.left,
    y: ndc.y * boundingClientRect.height + boundingClientRect.top,
  };
};

/**
 * Converts normalized device sizes to document coordinates
 * @param nds x and y representing width and height
 * @param relativeTo the HTML Element the coordinates are relative to, defaults to document.body
 * @returns x and y sizes in document space
 */
export const ndsToDocumentSize = (
  ndc: { x: number; y: number },
  relativeTo?: HTMLElement | SVGGraphicsElement
): { x: number; y: number } => {
  if (!relativeTo) relativeTo = document.body;
  const boundingClientRect = relativeTo.getBoundingClientRect();
  return {
    x: ndc.x * boundingClientRect.width,
    y: ndc.y * boundingClientRect.height,
  };
};

/**
 * Inverse of ndcToDocument
 * @param coordinates x and y coordinates in document space
 * @param relativeTo the HTML or SVG element the coordinates are relative to, defaults to document.body
 * @returns x and y coordinates in ndc space
 */
export const documentToNdc = (
  coordinates: { x: number; y: number },
  relativeTo?: HTMLElement | SVGGraphicsElement
): { x: number; y: number } => {
  if (!relativeTo) relativeTo = document.body;
  const boundingClientRect = relativeTo.getBoundingClientRect();
  return {
    x: (coordinates.x - boundingClientRect.left) / boundingClientRect.width,
    y: (coordinates.y - boundingClientRect.top) / boundingClientRect.height,
  };
};

/**
 * Inverse of ndsToDocumentSize
 * @param coordinates x and y width and height in document space
 * @param relativeTo the HTML or SVG element the coordinates are relative to, defaults to document.body
 * @returns x and y coordinates in ndc space
 */
export const documentSizeToNds = (
  coordinates: { x: number; y: number },
  relativeTo?: HTMLElement | SVGGraphicsElement
): { x: number; y: number } => {
  if (!relativeTo) relativeTo = document.body;
  const boundingClientRect = relativeTo.getBoundingClientRect();
  return {
    x: coordinates.x / boundingClientRect.width,
    y: coordinates.y / boundingClientRect.height,
  };
};

/**
 * Converts centered normalized device coordinates {x, y | x, y ∈ [-1, 1]} to normalized device coordinates {x, y | x, y ∈ [0, 1]}
 * @param cndc x and y coordinates in cndc system
 * @returns x and y in ndc system
 */
export const cndcToNdc = (cndc: {
  x: number;
  y: number;
}): { x: number; y: number } => {
  return { x: (cndc.x + 1) / 2, y: (cndc.y + 1) / 2 };
};

/**
 * Converts normalized device coordinates {x, y | x, y ∈ [0, 1]} to centered normalized device coordinates {x, y | x, y ∈ [-1, 1]}
 * @param ndc x and y coordinates in ndc system
 * @returns x and y in cndc system
 */
export const ndcToCndc = (ndc: {
  x: number;
  y: number;
}): { x: number; y: number } => {
  return { x: ndc.x * 2 - 1, y: ndc.y * 2 - 1 };
};

/**
 * Chains cndcToNdc, ndcToDocument, documentToSvg to transform cndc coordinates to SVG coordinates
 * @param cndc x and y coordinates in cndc space
 * @param svg the SVG element the coordinates are relative to
 * @returns x and y coordinates in SVG space
 */
export const cndcToSvg = (
  cndc: {
    x: number;
    y: number;
  },
  svg: SVGSVGElement,
  ignoreParentOffset?: boolean
): { x: number; y: number } => {
  return ndcToSvg(cndcToNdc(cndc), svg, ignoreParentOffset);
};

/**
 * Chains ndcToDocument, documentToSvg to transform ndc coordinates to SVG coordinates
 * @param ndc x and y coordinates in cndc space
 * @param svg the SVG element the coordinates are relative to
 * @returns x and y coordinates in SVG space
 */
export const ndcToSvg = (
  ndc: {
    x: number;
    y: number;
  },
  svg: SVGSVGElement,
  ignoreParentOffset?: boolean
): { x: number; y: number } => {
  return documentToSvg(
    ndcToDocument(ndc, !ignoreParentOffset ? svg.parentElement! : undefined),
    svg
  );
};

export const toSvgSize = (svgPos: {
  x: number;
  y: number;
}): { x: number; y: number } => {
  return { x: svgPos.x / 2, y: svgPos.y / 2 };
};
