import { StepResourceDto } from '@assemblio/shared/dtos';
import classes from './StepResource.module.scss';
import { ActionIcon, Group, Tooltip, Text } from '@mantine/core';
import { IconInfoCircle } from '@tabler/icons-react';
import { ResourceNameDisplay } from './components/ResourceNameDisplay/ResourceNameDisplay';

interface CompactStepResourceProps {
  relation: StepResourceDto;
}

export const CompactStepResource = ({ relation }: CompactStepResourceProps) => {
  return (
    <Group className={classes.wrapper} justify={'space-between'} p={'sm'}>
      <Group w={'100%'} wrap={'nowrap'}>
        <Text>{relation.value.amount}x</Text>

        <ResourceNameDisplay
          name={relation.resource.name}
          identifier={relation.resource.resourceNo}
          unit={relation.value.unit}
        />
        <Tooltip
          multiline
          w={220}
          withArrow
          transitionProps={{ duration: 200 }}
          label={relation.resource.text}
          position="left"
        >
          <ActionIcon w={'10%'} size={'xs'} variant={'ghost'}>
            <IconInfoCircle style={{ height: '90%', width: '90%' }} />
          </ActionIcon>
        </Tooltip>
      </Group>
      {relation.value.info && (
        <Text size={'sm'} c={'text-secondary'} w={'100%'}>
          Some Information Text about the resource and how to use it
        </Text>
      )}
    </Group>
  );
};
