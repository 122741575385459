import { Button, Divider, Flex, Modal, MultiSelect, Select, Skeleton, Stack } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { useForm } from '@mantine/form';

import {
  useCreateOperationalResource,
  useResourceByIdQuery,
  useUpdateOperationalResource,
} from '@assemblio/frontend/data-access';
import {
  OperationalResourceMainCategory,
  OperationalResourceSecondaryCategory,
  OperationalResourceUnits,
} from '@assemblio/type/resources';
import { Input } from '@assemblio/design-system';
import { CreateOperationalResourceDto, UpdateOperationalResourceDto } from '@assemblio/shared/dtos';
import { filterUndefinedUnits, getDirtyFields, transformEnumToSelectData } from './util';
import { UpsertResourceForm } from './types/upsertOperationalResource.types';
import { notifications } from '@mantine/notifications';

interface CreateOperationalResourceModalProps {
  resourceId?: string;
  opened: boolean;
  onClose: () => void;
}

export const UpsertOperationalResourceModal = ({
  resourceId: initialResourceId,
  opened,
  onClose,
}: CreateOperationalResourceModalProps) => {
  const [resourceId, setResourceId] = useState<string | undefined>(initialResourceId);
  const [isLoading, setIsLoading] = useState(false);
  const isUpdate = !!resourceId;

  const { data } = useResourceByIdQuery(resourceId);

  const showSuccessNotification = () => {
    notifications.show({
      message: isUpdate ? 'Resource updated successfully' : 'Resource created successfully',
    });
  };
  const showErrorNotification = () => {
    notifications.show({
      message: isUpdate ? 'Could not update resource' : 'Could not create resource',
    });
  };

  const createResourceMutation = useCreateOperationalResource();
  const updateResourceMutation = useUpdateOperationalResource();

  //TODO: check why schema cannot be used
  const form = useForm<UpsertResourceForm<typeof resourceId>>({
    mode: 'uncontrolled',
    enhanceGetInputProps: (payload) => {
      if (payload.field === 'allowedUnits') {
        return {
          onChange: (value: string[]) => {
            form.setFieldValue('allowedUnits', value as OperationalResourceUnits[]);
          },
        };
      }
      return payload;
    },
    //TODO: use schemas for validation once issues is resolved. Most basic validation for now.
    validate: {
      name: (value: string | null) => {
        if (!value) return 'Name is required';
        return null;
      },
      text: (value: string | null) => {
        if (!value) return 'Text is required';
        return null;
      },
      mainCategory: (value: string | null) => {
        if (!value) return 'Main category is required';
        return null;
      },
    },
    validateInputOnBlur: true,
  });

  const handleSubmitCreation = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (form.validate().hasErrors) {
      return;
    }
    setIsLoading(true);
    resourceId !== undefined
      ? updateResourceMutation.mutate(
          { id: resourceId, data: getFormValues(resourceId) },
          {
            onSuccess: () => {
              showSuccessNotification();
              setIsLoading(false);
            },
            onError: () => {
              showErrorNotification();
              setIsLoading(false);
            },
          }
        )
      : createResourceMutation.mutate(getFormValues(resourceId), {
          onSuccess: (data) => {
            setResourceId(data.id);
            showSuccessNotification();
            setIsLoading(false);
          },
          onError: () => {
            showErrorNotification();
            setIsLoading(false);
          },
        });
  };

  const getFormValues = <T extends string | undefined>(
    resourceId: T
  ): T extends string ? UpdateOperationalResourceDto : CreateOperationalResourceDto => {
    if (resourceId === undefined) {
      const { allowedUnits, name, resourceNo, secondaryCategory, mainCategory, text } = form.getValues();
      return {
        allowedUnits: (allowedUnits?.filter(filterUndefinedUnits) ?? []).map((unit) => unit),
        mainCategory: mainCategory,
        name: name,
        resourceNo: resourceNo,
        secondaryCategory: secondaryCategory,
        text: text,
      } as T extends string ? never : CreateOperationalResourceDto;
    } else {
      const dirtyValues = form.getDirty();
      return getDirtyFields(form.getValues(), dirtyValues) as T extends string ? UpdateOperationalResourceDto : never;
    }
  };

  const initializeEmptyForm = () => {
    form.initialize({
      name: '',
      text: '',
      resourceNo: '',
      mainCategory: undefined,
      secondaryCategory: undefined,
      allowedUnits: [],
    });
  };

  const initializeFormFromData = () => {
    if (!data) return;
    form.initialize({
      ...data,
    });
  };

  const handleCancel = () => {
    form.reset();
    createResourceMutation.reset();
    updateResourceMutation.reset();
    onClose();
  };

  useEffect(() => {
    if (!isUpdate) initializeEmptyForm();

    if (data && !form.initialized) initializeFormFromData();
  }, [data]);

  return (
    <Modal opened={opened} onClose={onClose} centered title={`${isUpdate ? 'Update' : 'Create'}  operational resource`}>
      <form onSubmit={handleSubmitCreation}>
        {form.initialized ? (
          <Stack mb={'lg'} px={'lg'} gap={'sm'}>
            <Input label={'Name'} {...form.getInputProps('name')} />
            <Input label={'Text'} {...form.getInputProps('text')} />
            <Input label={'Resource identifier'} {...form.getInputProps('resourceNo')} />
            <Select
              label="Main category"
              placeholder="Choose main category"
              data={transformEnumToSelectData(OperationalResourceMainCategory)}
              {...form.getInputProps('mainCategory')}
            />
            <Select
              label="Secondary category"
              placeholder="Choose secondary category"
              data={transformEnumToSelectData(OperationalResourceSecondaryCategory)}
              {...form.getInputProps('secondaryCategory')}
            />
            <MultiSelect
              label="Unit"
              placeholder="Choose applicable units"
              data={transformEnumToSelectData(OperationalResourceUnits)}
              {...form.getInputProps('allowedUnits')}
            />
          </Stack>
        ) : (
          <Stack mb={'lg'} px={'lg'} gap={'xl'}>
            <Skeleton mt={'.7rem'} mb={'1rem'} w={'100%'} h={'2rem'} />
            <Skeleton mb={'1rem'} w={'100%'} h={'2rem'} />
            <Skeleton mb={'1rem'} w={'100%'} h={'2rem'} />
            <Skeleton mb={'1rem'} w={'100%'} h={'2rem'} />
            <Skeleton mb={'1rem'} w={'100%'} h={'2rem'} />
            <Skeleton w={'100%'} h={'2rem'} />
          </Stack>
        )}
        <Divider c={'border-secondary'} />
        <Flex justify="flex-end" gap={'md'} p={'lg'}>
          <Button onClick={handleCancel} variant={'secondary'}>
            Close
          </Button>
          <Button
            loading={isLoading}
            type={'submit'}
            disabled={isLoading || !form.isValid() || !form.isDirty()}
            variant={'primary'}
          >
            {isUpdate ? 'Update' : 'Create'}
          </Button>
        </Flex>
      </form>
    </Modal>
  );
};
