import React from 'react';
import { Tabs } from '@mantine/core';
import classes from './TabContainer.module.scss';
import { DetailsTabContent } from './components/DetailsTab/DetailsTabContent';
import { ArtefactsTabContent } from './components/ArtefactsTab/ArtefactsTabContent';
import { VersionHistoryTab } from './components/VersionHistoryTab/VersionHistoryTab';
import { mockVersions } from './components/VersionHistoryTab/mocks/mockData';
import { ProductContentDto } from '@assemblio/shared/dtos';
import { useInstructionArtefactsCountQuery } from '@assemblio/frontend/data-access';
import { useNavigate, useParams } from 'react-router-dom';

interface TabsContainerProps {
  defaultValue?: string;
  product: ProductContentDto | undefined;
  isLoading?: boolean;
}

export const TabsContainer = ({ defaultValue = 'details', product, isLoading }: TabsContainerProps) => {
  const navigate = useNavigate();
  const { tab } = useParams();
  const { data: artefactCount } = useInstructionArtefactsCountQuery(product?.id);

  const handleTabChange = (value: string | null) =>
    navigate(`/explorer/product/${product?.id}/${value}`, { replace: true });

  return (
    <Tabs keepMounted={false} value={tab ?? defaultValue} onChange={handleTabChange}>
      <Tabs.List>
        <Tabs.Tab value="details">Details</Tabs.Tab>
        <Tabs.Tab value="artefacts">
          Artefacts <span className={classes.tab__count}>{artefactCount?.count}</span>
        </Tabs.Tab>
        {/*<Tabs.Tab value="version history">Version history</Tabs.Tab>*/}
        {/*<Tabs.Tab value="feedback">*/}
        {/*  Feedback<span className={classes.tab__count}>(0)</span>*/}
        {/*</Tabs.Tab>*/}
      </Tabs.List>

      <Tabs.Panel value="details">
        <DetailsTabContent product={product} isLoading={isLoading} />
      </Tabs.Panel>
      <Tabs.Panel value="artefacts">
        <ArtefactsTabContent product={product} />
      </Tabs.Panel>
      <Tabs.Panel value="version history">
        <VersionHistoryTab versions={mockVersions} />
      </Tabs.Panel>
      <Tabs.Panel value="feedback">FEEDBACK CONTENTS</Tabs.Panel>
    </Tabs>
  );
};
