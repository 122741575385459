import { AnnotationController, useAnnotationStore, useUIStore } from '@assemblio/frontend/stores';
import { SizeableAnnotation } from '@assemblio/shared/next-types';
import { useElementSize } from '@mantine/hooks';
import { forwardRef, PropsWithChildren, ReactNode, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { DraggableData, Rnd } from 'react-rnd';
import {
  cndcToNdc,
  documentSizeToNds,
  documentToNdc,
  ndcToCndc,
  ndcToDocument,
  ndsToDocumentSize,
} from '../CoordinateSystems';
import { useUpdateAnnotation } from '@assemblio/frontend/data-access';
import { usePlaybackState } from '@assemblio/frontend/hooks';

const TOOLBAR_THRESHOLD = 60;

const TOOLBAR_OFFSET = 10;

const TOOLBAR_HEIGHT = 40;

const TOOLBAR_RESIZE_HANDLE_STYLES = {
  height: '8px',
  width: '8px',
  backgroundColor: 'white',
  borderRadius: '50%',
  border: '1px solid black',
};

interface Props {
  annotationId: string;
  annotation: SizeableAnnotation;
  toolbar: ReactNode;
  initialMode: 'editing' | 'display';
  lockAspectRatio?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export interface ResizableInterface {
  setSize: (
    width?: number,
    height?: number,
    options?: {
      grow?: boolean;
      shrink?: boolean;
    }
  ) => void;
  setSizeParameters: (width: number, height: number) => void;
}

export const ResizableAnnotation = forwardRef<ResizableInterface, PropsWithChildren<Props>>(
  ({ annotationId, annotation, toolbar, children, lockAspectRatio, onClick }: PropsWithChildren<Props>, ref) => {
    const { ref: containerRef, width, height } = useElementSize<HTMLDivElement>();
    const [showToolbar, setShowToolbar] = useState(true);
    const [toolbarCoordinates, setToolbarCoordinates] = useState<[number, number, number, number] | undefined>(
      undefined
    );
    const [toolbarPosition, setToolbarPosition] = useState<'top' | 'bottom'>('top');

    const view = useUIStore((state) => state.view);
    const isAnimating = useUIStore((state) => state.isAnimating);
    const disabled = view === 'viewer' || isAnimating;
    const [dimensions, setDimensions] = useState({
      width: 0,
      height: 0,
      top: 0,
      left: 0,
    });
    const [initialized, setInitialized] = useState(false);

    const playbackState = usePlaybackState();

    const updateAnnotationMutation = useUpdateAnnotation();

    const { visible, mode, highlight } = useAnnotationStore((state) => {
      const annotationState = state.annotationMap.get(annotationId);
      return annotationState || { visible: true, mode: 'editing', highlight: false };
    });

    const persistAnnotation = (meta: Partial<SizeableAnnotation>) => {
      AnnotationController.updateAnnotation(annotationId, meta);
      mode === 'editing' && updateAnnotationMutation.mutate({ id: annotationId, meta });
    };

    useEffect(() => {
      const callback = () => {
        if (containerRef.current) {
          updateDimensions(containerRef.current);
        }
      };
      window.addEventListener('resize', callback);
      return () => window.removeEventListener('resize', callback);
    });

    const updateDimensions = useCallback(
      (element: HTMLDivElement) => {
        const { x: width, y: height } = ndsToDocumentSize(
          {
            x: annotation.size.width,
            y: annotation.size.height,
          },
          element
        );
        const { x: left, y: top } = ndcToDocument(cndcToNdc({ x: annotation.x, y: annotation.y }), element);
        setDimensions({ width, height, top, left });
        setInitialized(true);
      },
      [annotation]
    );

    useEffect(() => {
      if (containerRef.current) {
        updateDimensions(containerRef.current);
        setInitialized(true);
      }
    }, [containerRef, annotation, width, height, updateDimensions]);

    const setSizeParameters = (width: number, height: number) => {
      setDimensions({
        ...dimensions,
        width,
        height,
      });
    };

    const setSize = (
      w?: number,
      h?: number,
      options?: {
        grow?: boolean;
        shrink?: boolean;
      }
    ) => {
      if (initialized) {
        let height = annotation.size.height;
        let width = annotation.size.width;
        const initialHeight = height;
        const initialWidth = width;
        if (w !== undefined) {
          const ndcW = documentSizeToNds({ x: w, y: 0 }, containerRef.current || undefined).y;
          if ((options && options.grow && ndcW > width) || (options && options.shrink && ndcW < width)) {
            width = ndcW;
            setDimensions({
              width: w ? w : dimensions.width,
              height: dimensions.height,
              top: dimensions.top,
              left: dimensions.left,
            });
          }
        }
        if (h !== undefined) {
          const ndcH = documentSizeToNds({ x: 0, y: h }, containerRef.current || undefined).y;
          if ((options && options.grow && ndcH > height) || (options && options.shrink && ndcH < height)) {
            height = ndcH;
            setDimensions({
              width: dimensions.width,
              height: h ? h : dimensions.height,
              top: dimensions.top,
              left: dimensions.left,
            });
          }
        }
        if (width !== initialWidth || height !== initialHeight) {
          persistAnnotation({
            size: {
              width,
              height,
            },
          });
        }
      }
    };

    const updateToolbarPosition = (dragEndData?: DraggableData) => {
      if (containerRef.current) {
        const rect = containerRef.current.getBoundingClientRect();
        const current = { x: rect.left, y: rect.top };

        if (dragEndData) {
          current.x = dragEndData.x;
          current.y = dragEndData.y;
        } else {
          current.x = dimensions.left - rect.left;
          current.y = dimensions.top - rect.top;
        }

        const toolbarY = current.y;

        setToolbarCoordinates([dimensions.width, dimensions.height, current.x, toolbarY]);

        if (toolbarY < TOOLBAR_THRESHOLD && toolbarPosition === 'top') {
          setToolbarPosition('bottom');
        } else if (toolbarY > TOOLBAR_THRESHOLD && toolbarPosition === 'bottom') {
          setToolbarPosition('top');
        }
      }
    };

    useEffect(() => {
      setShowToolbar(mode === 'editing');
      if (mode === 'editing') {
        updateToolbarPosition();
        setShowToolbar(true);
      }
    }, [mode, dimensions.width]);

    useImperativeHandle(ref, () => {
      return { setSize, setSizeParameters };
    });

    const annotationClick = (e: React.MouseEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (!disabled) {
        onClick && onClick(e);
        AnnotationController.editAnnotation(annotationId);
      }
    };
    return (
      <>
        {showToolbar && (
          <div
            style={{
              height: toolbarCoordinates ? toolbarCoordinates[1] : 0,
              width: toolbarCoordinates ? toolbarCoordinates[0] : 0,
              position: 'absolute',
              left: toolbarCoordinates ? toolbarCoordinates[2] : 0,
              top: toolbarCoordinates ? toolbarCoordinates[3] : 0,
            }}
          >
            <div
              style={{
                position: 'relative',
                minWidth: '700px',
                height: `${TOOLBAR_HEIGHT}px`,
                zIndex: '5',
                left: '50%',
                transform: 'translateX(-50%)',
                bottom: toolbarPosition === 'top' ? `${TOOLBAR_HEIGHT + TOOLBAR_OFFSET}px` : undefined,
                top: toolbarPosition === 'bottom' ? `calc(100% + ${TOOLBAR_OFFSET}px)` : undefined,
              }}
            >
              {toolbar}
            </div>
          </div>
        )}
        <div
          ref={containerRef}
          style={{
            position: 'absolute',
            float: 'left',
            width: '100%',
            height: '100%',
            filter: highlight ? 'drop-shadow(0 0 1px rgb(255,255,255))' : 'initial',
            zIndex: highlight || mode === 'editing' ? 2 : 'initial',
            visibility: visible || highlight ? 'visible' : 'hidden',
          }}
        >
          <Rnd
            disableDragging={mode === 'display'}
            style={{
              pointerEvents: 'all',
            }}
            size={{
              width: `${dimensions.width}px`,
              height: `${dimensions.height}px`,
            }}
            maxWidth={'100%'}
            maxHeight={'100%'}
            onMouseDown={(e: MouseEvent) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            lockAspectRatio={lockAspectRatio}
            position={{
              x: dimensions.left - (containerRef.current ? containerRef.current.getBoundingClientRect().left : 0),
              y: dimensions.top - (containerRef.current ? containerRef.current.getBoundingClientRect().top : 0),
            }}
            onDragStop={(e, d) => {
              if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const current = { x: d.x + rect.left, y: d.y + rect.top };
                const delta = ndcToCndc(documentToNdc(current, containerRef.current || undefined));
                const position = {
                  x: delta.x,
                  y: delta.y,
                };
                setDimensions({
                  width: dimensions.width,
                  height: dimensions.height,
                  top: current.y,
                  left: current.x,
                });
                updateToolbarPosition(d);
                persistAnnotation(position);
              }
              setShowToolbar(true);
            }}
            onDragStart={() => setShowToolbar(false)}
            onResizeStop={(_e, direction, ref, delta, position) => {
              if (containerRef.current) {
                const rect = containerRef.current.getBoundingClientRect();
                const childRect = ref.getBoundingClientRect();
                const current = {
                  x: position.x + rect.left,
                  y: position.y + rect.top,
                };
                const location = ndcToCndc(documentToNdc(current, containerRef.current || undefined));
                const size = documentSizeToNds(
                  {
                    x: childRect.width,
                    y: childRect.height,
                  },
                  containerRef.current || undefined
                );
                setDimensions({
                  width: childRect.width,
                  height: childRect.height,
                  top: current.y,
                  left: current.x,
                });

                persistAnnotation({
                  ...location,
                  size: {
                    width: size.x,
                    height: size.y,
                  },
                });
              }
            }}
            enableResizing={mode !== 'display'}
            resizeHandleStyles={{
              topLeft: { ...TOOLBAR_RESIZE_HANDLE_STYLES, left: '-6px', top: '-6px' },
              bottomLeft: { ...TOOLBAR_RESIZE_HANDLE_STYLES, left: '-6px', bottom: '-3px' },
              topRight: { ...TOOLBAR_RESIZE_HANDLE_STYLES, right: '-3px', top: '-6px' },
              bottomRight: { ...TOOLBAR_RESIZE_HANDLE_STYLES, right: '-3px', bottom: '-3px' },
            }}
          >
            <div
              onClick={annotationClick}
              onMouseEnter={(e) => {
                if (playbackState !== 'playing') {
                  AnnotationController.setHovered(annotationId);
                  AnnotationController.setHighlight(annotationId, true);
                }
              }}
              onMouseLeave={(e) => {
                AnnotationController.setHovered();
                AnnotationController.setHighlight(annotationId, false);
              }}
              style={{
                width: '100%',
                height: '100%',
                cursor: disabled ? 'default' : mode === 'editing' ? 'move' : 'pointer',
                position: 'absolute',
                border: mode === 'editing' ? 'var(--border-brand)' : 0,
                borderRadius: mode === 'editing' ? '3px' : 0,
                marginLeft: mode === 'editing' ? '-2px' : 0,
                marginTop: mode === 'editing' ? '-2px' : 0,
                boxSizing: 'content-box',
                zIndex: '-1',
              }}
            >
              {children}
            </div>
          </Rnd>
        </div>
      </>
    );
  }
);
