import { Tag } from '@assemblio/design-system';
import { useModalContext } from '@assemblio/frontend/components';
import { useUIStore } from '@assemblio/frontend/stores';
import { Button, Group, Skeleton, Stack, Title } from '@mantine/core';
import { IconCirclePlusFilled } from '@tabler/icons-react';
import cx from 'clsx';
import { useContext } from 'react';
import { ItemCountBadge } from '../../components';
import { useExplorerRouteParams } from '../../hooks/ExplorerRouteParams.hook';
import { ExplorerItemType } from '../../pages/assembly-plans/types/items.types';
import { ExplorerTitleContext } from '../../providers/ExplorerTitleProvider';
import { BreadcrumbNavigation } from './components/BreadcrumbNavigation/BreadcrumbNavigation';
import { VersionBadge } from './components/VersionBadge/VersionBadge';
import classes from './Header.module.scss';
import { useUppyUploader } from '@assemblio/frontend/hooks';

interface HeaderProps {
  onOpenCreateProductModal: () => void;
}

export const Header = ({ onOpenCreateProductModal }: HeaderProps) => {
  const isViewer = useUIStore.getState().view === 'viewer';

  const { openModal } = useModalContext();
  const { projectId, productId, resourceId } = useExplorerRouteParams();

  const { uploadReady } = useUppyUploader();

  const { versionTag, itemCount, title, state, breadcrumbs, rightSection, bottomSection, noDefaultRightSection } =
    useContext(ExplorerTitleContext);

  const showAddFolderButton = !projectId && !productId;

  const showAddProductButton = !!projectId && !productId;

  const showAddProjectButton = !projectId && !productId;

  const openAddFolderModal = () => {
    openModal('create-explorer-item', { type: ExplorerItemType.Folder });
  };

  const openAddProjectModal = () => {
    openModal('create-explorer-item', { type: ExplorerItemType.Project });
  };

  const defaultRightSection =
    typeof rightSection !== 'undefined' ? null : (
      <>
        {showAddFolderButton && (
          <Button leftSection={<IconCirclePlusFilled />} variant={'secondary'} onClick={openAddFolderModal}>
            Add Folder
          </Button>
        )}
        {showAddProductButton && (
          <Button
            disabled={!uploadReady}
            leftSection={<IconCirclePlusFilled />}
            variant={'primary'}
            onClick={onOpenCreateProductModal}
          >
            Add Product
          </Button>
        )}
        {showAddProjectButton && (
          <Button leftSection={<IconCirclePlusFilled />} variant={'primary'} onClick={openAddProjectModal}>
            Add Project
          </Button>
        )}
      </>
    );

  return (
    <Stack
      className={cx(classes.container, {
        [classes.container__product_details]: !!productId || !!resourceId,
      })}
      gap={'sm'}
    >
      <Group wrap={'nowrap'} align={'end'} justify={'space-between'}>
        <Stack>
          <BreadcrumbNavigation path={breadcrumbs} />
          <Group gap={'md'} wrap={'nowrap'} data-cy="headerText">
            {title ? (
              <Title order={1} lineClamp={1}>
                {title}
              </Title>
            ) : (
              <Skeleton w={'300px'} h={'2.5rem'} />
            )}
            {itemCount && <ItemCountBadge count={itemCount} />}
            {state && <Tag state={state} />}
            {versionTag && <VersionBadge version={versionTag} />}
          </Group>
        </Stack>
        {!isViewer && noDefaultRightSection !== true && (
          <Group wrap={'nowrap'}>{typeof rightSection !== 'undefined' ? rightSection : defaultRightSection}</Group>
        )}
      </Group>
      {bottomSection && <>{bottomSection}</>}
    </Stack>
  );
};
