import { ProductOverviewDto } from '@assemblio/shared/dtos';
import { ExplorerGrid } from '../../../layout';
import { ProductCard, ProductCardSkeleton } from './ProductCard';

interface ProductGridProps {
  products: ProductOverviewDto[];
  onOpenProduct: (product: ProductOverviewDto) => void;
  onOpenDetails: (id: string) => void;
  isLoading?: boolean;
}

export const ProductGrid = ({ products, onOpenProduct, onOpenDetails, isLoading = false }: ProductGridProps) => {
  const items = products.map((product) => (
    <ProductCard
      onOpenProduct={() => onOpenProduct(product)}
      onOpenDetails={() => onOpenDetails(product.id)}
      item={product}
    />
  ));
  return <ExplorerGrid items={items} isLoading={isLoading} loadingPlaceholder={<ProductCardSkeleton />} />;
};
