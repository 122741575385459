import { ExportJobDto } from '@assemblio/shared/dtos';
import { Box, Card, Group, Loader, Progress, Stack, Text } from '@mantine/core';
import classes from './JobCard.module.scss';
import { IconMP4, IconPDF, Tag } from '@assemblio/design-system';
import { IconCheck, IconFolderFilled } from '@tabler/icons-react';
import { ExportJobType } from '@assemblio/type/job';
import { useJobUpdates } from './hooks/jobUpdate.hook';
import { JobContextMenu } from './components/JobContextMenu';

interface JobCardProps {
  job: ExportJobDto;
  onJobCompletion: () => void;
}

export const JobCard = ({ job, onJobCompletion }: JobCardProps) => {
  const [progress] = useJobUpdates({ jobId: job.id, onJobCompletion });

  const isInitializing = progress === undefined;
  const showProgressBar = Boolean(progress && progress > 0 && progress < 100);
  const isCompleted = Boolean(progress === 100);

  return (
    <Card p={'lg'} className={classes.card}>
      <Group h={'100%'} justify="space-between">
        <Group h={'100%'}>
          <Box className={classes.icon}>{getJobIcon(job.type)}</Box>
          <Stack gap={'xs'} justify="center">
            <Text fw={'bold'} c={'text-primary'}>
              Creating {job.createdFileName ?? job.type}
            </Text>
            <Group w={'100%'} h={'24px'}>
              {showProgressBar && <Progress w={'100%'} color={'var(--text-brand)'} value={progress ?? 0} />}
              {isCompleted ? (
                <Tag size={'xs'} leftSection={<IconCheck size={14} />}>
                  Completed
                </Tag>
              ) : null}
              {isInitializing && (
                <Tag size={'xs'} leftSection={<Loader color={'var(--text-brand)'} size={14} />}>
                  Initializing
                </Tag>
              )}
            </Group>
          </Stack>
        </Group>
        <Group>
          <JobContextMenu jobId={job.id} productId={job.productId} />
        </Group>
      </Group>
    </Card>
  );
};

const getJobIcon = (type: ExportJobType) => {
  switch (type) {
    case ExportJobType.EXPORT_VIDEO:
      return <IconMP4 />;
    case ExportJobType.EXPORT_PDF:
      return <IconPDF />;
    case ExportJobType.EXPORT_DOCX:
      return <IconPDF />;
    default:
      return <IconFolderFilled size={16} />;
  }
};
