import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  fetchFolder,
  fetchRootFolder,
  fetchV1Folder,
  fetchV1RootFolder,
  searchFoldersProjects,
} from './folder.requests';
import { ProjectFoldersDto } from '@assemblio/shared/dtos';
import { FetchFolder } from './types/folder.query.types';

export const useRootFolderQuery = () => {
  return useQuery(['rootFolder'], () => fetchRootFolder());
};

export const useV1RootFolderQuery = () => {
  return useQuery(['rootFolder'], () => fetchV1RootFolder());
};

export const useFolderQuery = (
  folderId: string | undefined,
  ownerFilter?: (folder: ProjectFoldersDto) => ProjectFoldersDto,
  usedBy?: string
) => {
  const queryClient = useQueryClient();
  return useQuery(
    ['folder', folderId, usedBy],
    () => {
      return fetchFolder(folderId);
    },
    {
      select: ownerFilter,
      enabled: Boolean(folderId),
      placeholderData: () => {
        return queryClient.getQueryData(['folder', folderId]);
      },
    }
  );
};

export const useV1FolderQuery = (data: FetchFolder) => {
  const queryClient = useQueryClient();
  return useQuery(
    ['folder', data.folderId, data.usedBy],
    () => {
      return fetchV1Folder(data);
    },
    {
      select: data.ownerFilter,
      enabled: Boolean(data.folderId),
      placeholderData: () => {
        return queryClient.getQueryData(['folder', data.folderId]);
      },
    }
  );
};

export const useSearchQuery = (searchName: string) => {
  return useQuery(
    ['search', searchName],
    () => {
      return searchFoldersProjects(searchName);
    },
    {
      enabled: Boolean(searchName),
    }
  );
};
