import { createElement, ReactNode } from 'react';
import { Skeleton, Table } from '@mantine/core';
import classes from './ExplorerTable.module.scss';
import { Th, ThProps } from './components/TableHeader';
import { EmptyView } from '../../components/EmptyView/EmptyView';

interface ExplorerTableProps {
  headerItems: ThProps[];
  items: ReactNode[];
  isLoading: boolean;
  loadingPlaceholder?: () => JSX.Element;
  emptyView?: ReactNode;
}

const SKELETON_ROW_COUNT = 3;

export const ExplorerTable = ({
  items,
  isLoading,
  headerItems,
  loadingPlaceholder = SkeletonRow,
  emptyView = <EmptyView />,
}: ExplorerTableProps) => {
  const isEmpty = !isLoading && items.length === 0;

  return isEmpty ? (
    emptyView
  ) : (
    <Table
      verticalSpacing={'sm'}
      classNames={{
        table: classes.table,
        thead: classes.table__head,
        td: classes.table__column,
      }}
    >
      <Table.Thead>
        <Table.Tr>
          {headerItems.map((header, index) => (
            <Th {...header} key={`header-${index}`} />
          ))}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {isLoading
          ? [...Array(SKELETON_ROW_COUNT)].map((_, index) =>
              createElement(loadingPlaceholder, {
                key: `loading-placeholder-${index}`,
              })
            )
          : items}
      </Table.Tbody>
    </Table>
  );
};

const SkeletonRow = () => (
  <Table.Tr>
    <Table.Td />
    <Table.Td>
      <Skeleton h={'2rem'} w={'15rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td />
  </Table.Tr>
);
