import { SearchField } from '@assemblio/design-system';
import { Box, Combobox, ComboboxItemGroup, Loader, Center, useCombobox } from '@mantine/core';
import classes from './ItemSearch.module.scss';
import { SearchResult } from './components/SearchResult';
import { ComboboxItem } from '@mantine/core/lib/components/Combobox/Combobox.types';

interface SearchResultType extends ComboboxItem {
  createdAt?: Date;
  owner?: string;
}

interface ItemSearchProps {
  onResultClick: (id: string) => void;
  searchTerm?: string;
  setSearchTerm?: (value: string) => void;
  isLoading?: boolean;
  results?: ComboboxItemGroup<SearchResultType>[];
  isEmpty?: boolean;
}

//TODO: add user info
export const ItemSearch = ({
  onResultClick,
  results,
  searchTerm,
  setSearchTerm,
  isLoading,
  isEmpty,
}: ItemSearchProps) => {
  const combobox = useCombobox();

  const hideResultDropdown = !searchTerm;

  const handleChangeSearchTerm = (value: string) => {
    combobox.openDropdown();
    combobox.updateSelectedOptionIndex();
    setSearchTerm && setSearchTerm(value);
  };

  return (
    <Box className={classes.container}>
      <Combobox
        onOptionSubmit={(optionValue) => {
          onResultClick(optionValue);
          setSearchTerm && setSearchTerm('');
          combobox.closeDropdown();
        }}
        store={combobox}
      >
        <Combobox.Target>
          <SearchField
            maw={'350px'}
            placeholder="Search"
            value={searchTerm}
            onChange={(event) => handleChangeSearchTerm(event.currentTarget.value)}
            onClick={() => combobox.openDropdown()}
            onFocus={() => combobox.openDropdown()}
            onBlur={() => combobox.closeDropdown()}
          />
        </Combobox.Target>

        <Combobox.Dropdown hidden={hideResultDropdown}>
          {isLoading ? (
            <Center>
              <Loader />
            </Center>
          ) : (
            <Combobox.Options>
              {isEmpty ? (
                <Combobox.Empty>Nothing found</Combobox.Empty>
              ) : (
                results?.map((result) => {
                  return (
                    <Combobox.Group key={result.group} label={result.group}>
                      {result.items.map((item) => (
                        <SearchResult
                          key={item.value}
                          name={item.label}
                          id={item.value}
                          date={item.createdAt}
                          user={item.owner}
                        />
                      ))}
                    </Combobox.Group>
                  );
                })
              )}
            </Combobox.Options>
          )}
        </Combobox.Dropdown>
      </Combobox>
    </Box>
  );
};
