import { useExplorerTitle } from '../../hooks';
import { useSortingOptions } from '../../components';
import { OPERATIONAL_RESOURCE_QUERY_SORTING_OPTIONS } from './constants/recentQuerySortOptions';
import { useEffect, useMemo } from 'react';
import { QueryOperationalResourceDto } from '@assemblio/shared/dtos';
import { useSearchParams } from 'react-router-dom';
import { OperationalResourceSortProps } from '@assemblio/type/resources/sort-props.enum';
import { ResourceDisplay } from '../../components/ResourcesDisplay/ResourcesDisplay';
import { useOperationalResourceQuery } from '../../../../../shared/data-access/src/lib/resources';
import { QueryOrder } from '@assemblio/type/common';
import { OperationalResourceMainCategory, OperationalResourceSecondaryCategory } from '@assemblio/type/resources';
import { Button } from '@mantine/core';
import { useModalContext } from '@assemblio/frontend/components';
import { IconCirclePlusFilled } from '@tabler/icons-react';

export const OperationalResources = () => {
  const { openModal } = useModalContext();

  const { sortingValue, handleSort } = useSortingOptions<OperationalResourceSortProps>(
    OPERATIONAL_RESOURCE_QUERY_SORTING_OPTIONS
  );
  const [searchParams] = useSearchParams();

  const queryData: QueryOperationalResourceDto = useMemo((): QueryOperationalResourceDto => {
    return {
      orderBy: sortingValue?.queryValue,
      orderType: sortingValue?.ascending ? QueryOrder.ASC : QueryOrder.DESC,
      mainCategory: (searchParams.get('mainCategory') as OperationalResourceMainCategory) ?? undefined,
      secondaryCategory: (searchParams.get('secondaryCategory') as OperationalResourceSecondaryCategory) ?? undefined,
    };
  }, [sortingValue, searchParams]);

  const { data: resources, isLoading, isRefetching, refetch } = useOperationalResourceQuery(queryData);

  const rightSection = useMemo(
    () => (
      <Button
        onClick={() => openModal('upsert-operational-resource', {})}
        variant={'primary'}
        leftSection={<IconCirclePlusFilled />}
      >
        Add Resource
      </Button>
    ),
    []
  );

  useExplorerTitle('Operational Resources', {
    title: {
      itemCount: resources?.length,
    },
    rightSection,
  });

  useEffect(() => {
    refetch();
  }, [sortingValue, searchParams]);

  return (
    <ResourceDisplay<OperationalResourceSortProps>
      resources={resources ?? []}
      isLoading={isLoading}
      isRefetching={isRefetching}
      sortingValue={sortingValue}
      onSort={handleSort}
      sortingOptions={OPERATIONAL_RESOURCE_QUERY_SORTING_OPTIONS}
    />
  );
};
