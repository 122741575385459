import { Uppy } from '@uppy/core';
import Tus from '@uppy/tus';
import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { FileMetaData, UppyConfig } from './uppyUploader.types';

const DEFAULT_UPPY_CONFIG: UppyConfig = {
  acceptedFileTypes: ['.step', '.stp', '.p21', '.jt'],
  minNumberOfFiles: 1,
  maxChunkSize: 50 * 1024 * 1024,
  maxFileSize: 1 * 1024 * 1024 * 1024,
};

const UppyContext = createContext<Uppy<FileMetaData> | null>(null);

export const UppyProvider = ({ children }: PropsWithChildren) => {
  const { acceptedFileTypes, minNumberOfFiles, maxChunkSize, maxFileSize } = DEFAULT_UPPY_CONFIG;
  // using split and trim to use the pre-existing file types array string value
  const allowedFileTypes = acceptedFileTypes?.map((item) => item.trim());

  const [uppy] = useState(() =>
    new Uppy<FileMetaData>({
      id: 'uppyInstance',
      autoProceed: false,
      allowMultipleUploadBatches: false,
      restrictions: {
        allowedFileTypes,
        minNumberOfFiles,
        maxNumberOfFiles: null,
        maxFileSize,
      },
      onBeforeFileAdded: () => true,
    }).use(Tus<FileMetaData, Record<string, never>>, {
      endpoint: '/upload-api/files',
      onBeforeRequest: (req, file) => {
        const { parentFolderId, projectId, fileName, token } = file.meta;

        // Abort the upload if the required meta fields are not set
        if (!parentFolderId || !projectId || !fileName) {
          console.debug(`Aborting upload - required meta fields are not set`, parentFolderId, projectId, fileName);
          req.abort().then(() => {
            uppy.cancelAll();
          });
          return;
        } else {
          req.setHeader('FolderId', parentFolderId);

          req.setHeader('ProjectId', projectId);

          req.setHeader('ProductName', fileName);
        }

        req.setHeader('OriginalFileName', file.name ?? '');
        req.setHeader('Token', `Bearer ${token}`);
      },
      onError(error) {
        console.log(error);
      },
      storeFingerprintForResuming: false,
      id: 'tus',
      chunkSize: maxChunkSize,
    })
  );

  return <UppyContext.Provider value={uppy}>{children}</UppyContext.Provider>;
};

export const useUppy = () => {
  const context = useContext(UppyContext);
  if (!context) {
    throw new Error('useUppy must be used within a UppyProvider');
  }
  return context;
};
