import { Button, Group, Paper, Pill, rem, Skeleton, Table, Text } from '@mantine/core';
import { OperationalResourceDto } from '@assemblio/shared/dtos';
import classes from './OperationalResourceRow.module.scss';
import { IconChevronRight } from '@tabler/icons-react';

interface OperationalResourceTableRowProps {
  resource: OperationalResourceDto;
  onEditResource: (id: string) => void;
  onViewUsage: (id: string) => void;
}

export const OperationalResourceRow = ({ resource, onEditResource, onViewUsage }: OperationalResourceTableRowProps) => {
  const { id, mainCategory, secondaryCategory, name, text, allowedUnits } = resource;

  return (
    <Table.Tr key={`operational-resource-row-${id}`} className={classes.row}>
      <Table.Td>
        <Text variant={'italic'}>{resource.resourceNo}</Text>
      </Table.Td>
      <Table.Td>
        <Text fw={800} variant={'medium'}>
          {name.toUpperCase()}
        </Text>
      </Table.Td>
      <Table.Td>
        <Paper withBorder p="sm">
          <Group justify="start">
            <Text size={'sm'} fw={500}>
              {mainCategory}
            </Text>
            <IconChevronRight style={{ width: rem(16), height: rem(16) }} stroke={2} />{' '}
            {secondaryCategory ? (
              <Text size={'sm'} fw={500}>
                {secondaryCategory}
              </Text>
            ) : (
              <Text fs="italic" c="dimmed" size={'sm'} fw={500}>
                No Secondary
              </Text>
            )}
          </Group>
        </Paper>
      </Table.Td>
      <Table.Td>
        <Text variant={'medium'}>{text}</Text>
      </Table.Td>
      <Table.Td>
        {allowedUnits?.map((unit, index) => (
          <Pill key={`allowed-units-pill-${id}-${index}`} m={2}>
            {unit}
          </Pill>
        ))}
      </Table.Td>
      <Table.Td>
        <Group wrap={'nowrap'} justify={'end'} align={'center'}>
          <Button onClick={() => onEditResource(id)} variant={'secondary'}>
            Edit
          </Button>
          <Button onClick={() => onViewUsage(id)} variant={'secondary'}>
            View Usage
          </Button>
        </Group>
      </Table.Td>
    </Table.Tr>
  );
};

export const OperationalResourceRowSkeleton = () => (
  <Table.Tr>
    <Table.Td>
      <Skeleton h={'2rem'} w={'15rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td>
      <Skeleton h={'2rem'} w={'5rem'} />
    </Table.Td>
    <Table.Td />
    <Table.Td />
  </Table.Tr>
);
