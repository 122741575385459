import { CameraControlsAPI } from '@assemblio/frontend/types';
import { useThree } from '@react-three/fiber';
import { forwardRef, useEffect, useRef } from 'react';
import { Camera, EventDispatcher, Matrix4, OrthographicCamera, Vector3 } from 'three';
import { documentToNdc, Point2D } from './CoordinateSystems';
import { cameraEvents } from '@assemblio/frontend/events';
import { useGesture } from '@use-gesture/react';

type InteractionFunction = (camera: Camera, delta: Point2D) => void;

export class Controls extends EventDispatcher {
  public enabled = true;
  private tempVector1 = new Vector3(1, 0, 0);
  private tempVector2 = new Vector3(0, 1, 0);
  private tempTransformationMatrix = new Matrix4();
  private tempOperationMatrix = new Matrix4();
  // Call this method to handle camera movement (drag/rotate)
  private interactions: Record<string, InteractionFunction> = {
    rotate: (camera: Camera, delta: Point2D) => {
      // The target is always placed relative to the camera
      // distance units along the negative relative z-Axis
      const target = this.tempVector1
        .set(0, 0, -1)
        .multiplyScalar(camera.distance)
        .applyQuaternion(camera.quaternion)
        .add(camera.position);

      // Read from bottom to top, as that is the order in which the
      // operations are applied.
      this.tempTransformationMatrix.identity();
      const transformationMatrix = this.tempTransformationMatrix
        // 4. Move Camera center back to original position
        .makeTranslation(target.clone())
        // 3. Rotate camera around the global y-Axis
        .multiply(
          this.tempOperationMatrix.identity().makeRotationAxis(this.tempVector2.set(0, 1, 0), delta.x * Math.PI * 2)
        )
        // 2. Rotate the camera around the local x-Axis
        .multiply(
          this.tempOperationMatrix
            .identity()
            .makeRotationAxis(this.tempVector2.set(1, 0, 0).applyQuaternion(camera.quaternion), delta.y * Math.PI * 2)
        )
        // 1. Center camera on the target point
        .multiply(this.tempOperationMatrix.identity().makeTranslation(target.clone().multiplyScalar(-1)));

      camera.applyMatrix4(transformationMatrix);
    },

    pan: (camera: Camera, delta: Point2D) => {
      const orthographicCamera = camera as OrthographicCamera;
      const localXAxisTranslation = this.tempVector1.set(1, 0, 0);
      const localYAxisTranslation = this.tempVector2.set(0, 1, 0);

      // Create local x-Axis, multiply by the movement delta
      localXAxisTranslation.applyQuaternion(camera.quaternion).multiplyScalar(delta.x * (1 / orthographicCamera.zoom));

      // Multiply global y-Axis by movement delta
      localYAxisTranslation.multiplyScalar(-delta.y * (1 / orthographicCamera.zoom));

      // Add movements to position
      camera.position.add(localXAxisTranslation).add(localYAxisTranslation);
    },

    zoom: (camera: Camera, delta: Point2D) => {
      // Multiply the y-delta with the current camera zoom,
      // then add it to the current camera zoom to make the
      // zoom steps smaller the smaller the zoom gets and vice
      // versa
      const orthographicCamera = camera as OrthographicCamera;
      orthographicCamera.zoom += orthographicCamera.zoom * delta.y;
      // Updating zoom necessitates updating the projection matrix
      orthographicCamera.updateProjectionMatrix();
    },
  };

  public handleInteraction(interactionType: 'pan' | 'rotate' | 'zoom', delta: Point2D, camera: Camera) {
    if (!this.enabled) return;

    if (interactionType !== 'zoom') {
      delta = documentToNdc(delta);
    }
    this.interactions[interactionType](camera, delta);
    cameraEvents.dispatchEvent('update');
  }

  setTarget(target: Vector3, camera: Camera) {
    camera.distance = target.distanceTo(camera.position);
    camera.lookAt(target);
    cameraEvents.dispatchEvent('update');
  }
}

export interface OrbiterProps {
  active?: boolean;
}

export const Orbiter = forwardRef<CameraControlsAPI, OrbiterProps>(({ active }, ref) => {
  const { camera, gl, set, get } = useThree();
  const controlsRef = useRef<Controls>();

  useGesture(
    {
      onDrag: ({ delta: [dx, dy], touches, event }) => {
        const delta = { x: -dx, y: -dy };

        const isPan = touches === 2 || (event instanceof MouseEvent && event.buttons === 2);
        const interactionType = isPan ? 'pan' : 'rotate';
        controlsRef.current?.handleInteraction(interactionType, delta, camera);
      },
      onPinch: ({ delta: [diff], memo }) => {
        if (Math.abs(diff) > 0.01) {
          controlsRef.current?.handleInteraction('zoom', { x: 0, y: Math.sign(diff) / 50 }, camera);
        }
      },

      onWheel: ({ delta: [, dy] }) => {
        controlsRef.current?.handleInteraction('zoom', { x: 0, y: Math.sign(dy) / -30 }, camera);
      },
    },
    {
      target: gl.domElement,
      eventOptions: { passive: false },
      drag: { pointer: { buttons: [1, 2, 4], mouse: true }, filterTaps: true },
    }
  );

  // Make controls play nice with r3f by registering them as defaults that have an enabled attribute.
  // https://github.com/pmndrs/drei/blob/9edcade6e4cb3aba2ed11ff5432cfcae76189548/src/core/OrbitControls.tsx#L96C11-L96C11
  // https://github.com/pmndrs/drei/blob/9edcade6e4cb3aba2ed11ff5432cfcae76189548/src/core/TransformControls.tsx#L8C6-L8C20
  // https://github.com/pmndrs/drei/blob/9edcade6e4cb3aba2ed11ff5432cfcae76189548/src/core/TransformControls.tsx#L81
  useEffect(() => {
    controlsRef.current = new Controls();
    const oldControls = get().controls;
    set({ controls: controlsRef.current });

    return () => set({ controls: oldControls });
  }, [set, get]);

  return controlsRef.current ? <primitive ref={ref} object={controlsRef.current} dispose={null} /> : null;
});
