import { SortingOption } from '../../../types';
import { OperationalResourceSortProps } from '@assemblio/type/resources/sort-props.enum';

export const OPERATIONAL_RESOURCE_QUERY_SORTING_OPTIONS: SortingOption<OperationalResourceSortProps>[] = [
  {
    value: 'name-desc',
    queryValue: OperationalResourceSortProps.name,
    label: 'Name (A to Z)',
    ascending: true,
  },
  {
    value: 'name-asc',
    queryValue: OperationalResourceSortProps.name,
    label: 'Name (Z to A)',
    ascending: false,
  },
  {
    value: 'updatedAt',
    queryValue: OperationalResourceSortProps.updatedAt,
    label: 'Last modified',
    ascending: true,
  },
  // {
  //   value: 'name-asc',
  //   queryValue: ProductSortProps.name,
  //   label: 'Name (Z to A)',
  //   ascending: false,
  // },
  // {
  //   value: 'state',
  //   queryValue: ProductSortProps.state,
  //   label: 'State (A to Z)',
  //   ascending: false,
  // },
];
