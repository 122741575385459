import { axiosHandler } from '../utils/axios-utils';
import { CreateMediaVideoExport, CreatePDFExport, CreateVideoExport } from './types/job.mutation.types';
import { ExportJobDto, JobDto, MultiExportDto } from '@assemblio/shared/dtos';

export const deleteJob = async (instructionId: string, jobId: string) => {
  return axiosHandler('delete', `/instructions/${instructionId}/jobs/${jobId}`);
};

export const retryJob = async (instructionId: string, jobId: string) => {
  return axiosHandler('post', `/instructions/${instructionId}/jobs/${jobId}/retry`);
};

export const fetchJobsForInstruction = async (instructionId: string): Promise<Array<JobDto>> => {
  return axiosHandler('get', `/instructions/${instructionId}/jobs`);
};

export const exportVideo = async (videoExport: CreateVideoExport): Promise<JobDto> => {
  return axiosHandler('post', `/instructions/${videoExport.id}/export/video`, {
    data: videoExport.data,
  });
};

export const exportPDF = async (pdfExport: CreatePDFExport): Promise<JobDto> => {
  return axiosHandler('post', `/instructions/${pdfExport.id}/export/document`, {
    data: pdfExport.data,
  });
};

export const exportMediaVideo = async (mediaVideoExport: CreateMediaVideoExport): Promise<JobDto> => {
  return axiosHandler('post', `/instructions/${mediaVideoExport.id}/export/media-video`, {
    data: mediaVideoExport.data,
  });
};

export const fetchExportJobs = async (productId: string | undefined): Promise<ExportJobDto[]> => {
  return typeof productId === 'undefined'
    ? Promise.reject(new Error('Invalid Id'))
    : axiosHandler('get', `/v1/instructions/${productId}/export/jobs`);
};

export const exportToMultipleFormats = async (exportDto: MultiExportDto) => {
  return axiosHandler('post', `/v1/instructions/${exportDto.instructionId}/export`, { data: exportDto });
};
