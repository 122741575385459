import { Combobox, Loader, useCombobox } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useDebouncedValue } from '@mantine/hooks';
import { useOperationalResourceQuery } from '@assemblio/frontend/data-access';
import { SearchField } from '@assemblio/design-system';

interface ResourceSelectProps {
  selectedResourceId: string | undefined;
  onSubmitResource: (resourceId: string) => void;
}

export function ResourceSelect({ selectedResourceId, onSubmitResource }: ResourceSelectProps) {
  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
  });

  const [search, setSearch] = useState<string>('');
  const [debouncedValue] = useDebouncedValue(search, 500);
  const [empty, setEmpty] = useState(false);

  const { data, isLoading, refetch, isRefetching } = useOperationalResourceQuery({
    name: debouncedValue.length > 0 ? debouncedValue : undefined,
  });

  const options = (data || []).map((item) => (
    <Combobox.Option value={item.id} key={item.id}>
      {item.name}
    </Combobox.Option>
  ));

  const getNameFromValue = (value: string | undefined) => {
    return data?.find((item) => item.id === value)?.name || '';
  };

  useEffect(() => {
    refetch().then((res) => {
      if (!res.data) {
        setEmpty(true);
        return;
      }
      setEmpty(res.data.length === 0 && search.length > 0);
      if (search.length === 0) combobox.closeDropdown();
    });

    if (debouncedValue && !combobox.dropdownOpened && !!data) combobox.openDropdown();
  }, [debouncedValue]);

  useEffect(() => {
    setSearch(getNameFromValue(selectedResourceId));
  }, [selectedResourceId]);

  return (
    <Combobox
      onOptionSubmit={(optionValue) => {
        onSubmitResource(optionValue);
        combobox.closeDropdown();
      }}
      withinPortal={false}
      store={combobox}
    >
      <Combobox.Target>
        <SearchField
          w={'70%'}
          placeholder="Select resource to add..."
          value={search}
          onChange={(event) => {
            setSearch(event.currentTarget.value);
            combobox.resetSelectedOption();
          }}
          onBlur={() => combobox.closeDropdown()}
          rightSection={isRefetching && <Loader c={'text-brand'} size={14} />}
        />
      </Combobox.Target>

      <Combobox.Dropdown hidden={data === null}>
        <Combobox.Options>
          {options}
          {isLoading && <Loader c={'text-brand'} />}
          {empty && <Combobox.Empty>No results found</Combobox.Empty>}
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  );
}
