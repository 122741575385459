import { StepCameraSettingsDto } from '@assemblio/shared/next-types';
import { Transform } from '@assemblio/type/3d';
import { QueryClient } from '@tanstack/react-query';
import produce from 'immer';
import _ from 'lodash';
import { Box3, Object3D, Quaternion, Vector3 } from 'three';
import {
  CanvasController,
  ImperativeModelController,
  MachineController,
  ModelController,
  ModelVisibilityController,
  ProjectController,
  SequenceController,
  StepController,
} from '.';
import { asArray } from '../helper/array.transform';
import { StepIndex } from '../indexes/StepIndex';
import { AspectRatio, useCanvasStore, useModelStore, useProjectStore } from '../stores';
import { UI, useUIStore } from '../stores/UIStore';
import { PartInformation } from '../stores/types/model.types';
import { sanitizeCameraSettings } from './step/helper';

export namespace UIController {
  export const setQueryClient = (client: QueryClient): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.queryClient = client;
      })
    );
  };

  export const setShowStepActions = (show: boolean): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.showStepActions = show;
      })
    );
  };

  export const setAnnotationAssetsLoaded = (loaded: boolean): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.annotationAssetsLoaded = loaded;
      })
    );
  };

  export const setDragging = (dragging: boolean): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.isDragging = dragging;
      })
    );
  };

  export const refreshTransformGizmo = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.transformGizmo.refresh = !state.transformGizmo.refresh;
      })
    );
  };

  export const setSnappingAngle = (snappingAngle: number) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.transformGizmo.snappingAngle = snappingAngle;
      })
    );
  };

  export const showTransformGizmo = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.transformGizmo.show = true;
      })
    );
  };

  export const hideTransformGizmo = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.transformGizmo.show = false;
      })
    );
  };

  export const setPartContext = (gltfIndex?: number) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.partContext = gltfIndex;
      })
    );
  };

  export const setAssemblyContext = (gltfIndex?: number) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.assemblyContext = gltfIndex;
      })
    );
  };

  export const selectStepById = (selectedStep?: string): void => {
    if (selectedStep && StepController.getStep(selectedStep)) {
      useUIStore.setState(
        produce<UI>((state) => {
          state.selectedStep = {
            id: selectedStep,
            index: StepController.getStepDisassemblyOrderById(selectedStep),
          };
        })
      );
    } else {
      useUIStore.setState(
        produce<UI>((state) => {
          state.selectedStep = undefined;
        })
      );
    }
  };

  export const deselectStep = (): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.selectedStep = undefined;
      })
    );
  };

  export const setFilter = (showExcluded: boolean, showDisassembled: boolean): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.hierarchyFilter.showExcluded = showExcluded;
        state.hierarchyFilter.showDisassembled = showDisassembled;
      })
    );
  };

  export const selectPartsInStep = (stepId: string) => {
    const selectedStep = StepController.getStep(stepId);
    if (selectedStep && selectedStep.data) {
      const gltfIndexes = selectedStep.data.parts.map((part) => part.partGltfIndex);
      UIController.selectParts(gltfIndexes);
      UIController.expandParentAssemblies(gltfIndexes);
    }
  };

  export const setHighlightedAnnotation = (annotationId?: string) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.highlightedAnnotation = annotationId;
      })
    );
  };

  // Wrapper around isModelExcluded in case any other conditions will occure in the future
  export const isSelectable = (gltfIndex: number): boolean => {
    const showDisassembled = useUIStore.getState().hierarchyFilter.showDisassembled;

    const modelExcluded = ModelController.isModelExcluded(gltfIndex);

    if (showDisassembled) return !modelExcluded;
    else {
      return !modelExcluded && !StepController.isPartDisassembled(gltfIndex);
    }
  };

  export const setSelectedParts = (gltfIndex: number | number[]): void => {
    deselectAllParts();
    const selectedParts = asArray(gltfIndex);
    useUIStore.setState(
      produce<UI>((state) => {
        selectedParts.forEach((gltfIndex) => {
          state.selectedPartSet.add(gltfIndex);
        });
      })
    );
  };

  export const selectParts = (gltfIndex: number | number[]): void => {
    const selectedParts = asArray(gltfIndex);
    useUIStore.setState(
      produce<UI>((state) => {
        selectedParts.forEach((gltfIndex) => {
          state.selectedPartSet.add(gltfIndex);
        });
      })
    );
  };

  export const expandStepGroup = (groupId: string): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.expandedStepGroups.add(groupId);
      })
    );
  };

  export const expandStepGroupByStepId = (stepId: string) => {
    const stepGroup = StepIndex.getStep(stepId);
    if (stepGroup) {
      expandStepGroup(stepGroup.id);
    }
  };

  export const collapseStepGroup = (groupId: string): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.expandedStepGroups.delete(groupId);
      })
    );
  };

  export const getSelectedParts = (): Array<Object3D> => {
    return Array.from(useUIStore.getState().selectedPartSet).reduce<Array<Object3D>>((result, gltfIndex) => {
      const model = ImperativeModelController.getModel(gltfIndex);
      if (model) {
        result.push(model);
      }
      return result;
    }, []);
  };

  export const highlightSelectedParts = () => {
    // This is a hack that is necessary because of the LoadingTransition
    // selecting the first step. In Kim it is not wanted that the parts are highlighted
    const view = useUIStore.getState().view;
    if (view === 'viewer') return;

    const selectedParts = Array.from(useUIStore.getState().selectedPartSet);
    selectedParts.forEach((gltfIndex) => {
      ImperativeModelController.setColor(gltfIndex, 0x00ffff);
      ImperativeModelController.setTransparent(gltfIndex, true);
    });
  };

  export const unhighlightSelectedParts = () => {
    const selectedParts = Array.from(useUIStore.getState().selectedPartSet);
    selectedParts.forEach((gltfIndex) => {
      const partInformation = useModelStore.getState().modelInformationMap.get(gltfIndex) as PartInformation;

      if (partInformation) {
        ImperativeModelController.setColor(gltfIndex, partInformation.color);
      }
      ModelVisibilityController.setPartTransparent(gltfIndex, false);
    });
  };

  export const markCollisionOnSelectedParts = () => {
    const selectedParts = Array.from(useUIStore.getState().selectedPartSet);
    selectedParts.forEach((gltfIndex) => {
      ImperativeModelController.setColor(gltfIndex, 0xff0000);
    });
  };

  export const isPartSelected = (gltfIndex: number): boolean => {
    return useUIStore.getState().selectedPartSet.has(gltfIndex);
  };

  export const isAssemblyExpanded = (gltfIndex: number): boolean => {
    return useUIStore.getState().expandedAssemblies.has(gltfIndex);
  };

  export const expandParentAssemblies = (partGltfIndexes: number | number[]) => {
    const gltfIndexes = Array.isArray(partGltfIndexes) ? partGltfIndexes : [partGltfIndexes];
    gltfIndexes.forEach((gltfIndex) => {
      const parentGltfIndexes = ProjectController.getParentGltfIndexes(gltfIndex);
      if (parentGltfIndexes.length > 0) expandAssemblies(parentGltfIndexes);
    });
  };

  export const expandAssemblies = (gltfIndexes: number[]) => {
    const collapsedAssemblies = gltfIndexes.filter((gltfIndex) => !isAssemblyExpanded(gltfIndex));
    if (collapsedAssemblies.length > 0) {
      useUIStore.setState(
        produce<UI>((state) => {
          gltfIndexes.forEach((gltfIndex) => {
            state.expandedAssemblies.add(gltfIndex);
          });
        })
      );
    }
  };

  export const expandAssembly = (gltfIndex: number) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.expandedAssemblies.add(gltfIndex);
      })
    );
  };

  export const collapseAssembly = (gltfIndex: number) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.expandedAssemblies.delete(gltfIndex);
      })
    );
  };

  export const deselectParts = (selectedGltfIndexes: number | number[]): void => {
    const gltfIndices = asArray(selectedGltfIndexes);
    useUIStore.setState(
      produce<UI>((state) => {
        gltfIndices.forEach((gltfIndex) => {
          state.selectedPartSet.delete(gltfIndex);
        });
      })
    );
    if (useUIStore.getState().selectedPartSet.size === 0) {
      UIController.hideTransformGizmo();
    }
  };

  export const deselectAllParts = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.selectedPartSet = new Set<number>();
      })
    );
  };

  export const deselectAssemblies = (selectedGltfIndices: number | number[]): void => {
    const gltfIndices = asArray(selectedGltfIndices);
    gltfIndices.forEach((gltfIndex) => {
      const assembly = useProjectStore.getState().input.assemblies.find((assembly) => assembly.gltfIndex === gltfIndex);
      if (assembly) {
        deselectParts(assembly.parts);
        deselectAssemblies(assembly.assemblies);
      }
    });
  };

  export const setPathSegment = (stepId: string, segmentIndex?: number): boolean => {
    let isSelected = true;
    useUIStore.setState(
      produce<UI>((state) => {
        if (segmentIndex === undefined || state.selectedPathSegmentMap.get(stepId) === segmentIndex) {
          state.selectedPathSegmentMap.delete(stepId);
          isSelected = false;
        } else {
          state.selectedPathSegmentMap.set(stepId, segmentIndex);
        }
      })
    );
    return isSelected;
  };

  export const setCameraTransformFromSelectedStep = () => {
    const selectedStep = StepController.getSelectedStep();
    if (selectedStep) {
      CanvasController.setCameraTransform(_.cloneDeep(selectedStep.cameraSettings.transform));
      CanvasController.setCameraZoom(selectedStep.cameraSettings.zoom);
      CanvasController.setCameraDistance(selectedStep.cameraSettings.distance);
    }
  };

  export const setCameraGizmoTransform = (transform?: Transform) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.cameraGizmoTransform = transform;
      })
    );
  };

  export const setSelectionBounds = (bounds: Box3) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.selectionBounds = bounds;
      })
    );
  };

  export const setSidebarWidth = (side: 'e' | 'w', width: number) => {
    useUIStore.setState(
      produce<UI>((state) => {
        if (side === 'e') state.sidebarWidth.left = width;
        else state.sidebarWidth.right = width;
      })
    );
  };
  export const activateCameraControls = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.cameraControlsActive = true;
      })
    );
  };

  export const deactivateCameraControls = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.cameraControlsActive = false;
      })
    );
  };

  export const setShowToolbar = (show: boolean): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.toolbarActive = show;
      })
    );
  };

  export const setShowViewportControls = (show: boolean): void => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.viewportControlsActive = show;
      })
    );
  };

  export const showCameraGizmo = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.showCameraGizmo = true;
      })
    );
  };

  export const hideCameraGizmo = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.showCameraGizmo = false;
      })
    );
  };

  export const showLineRenderer = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.showLineRenderer = true;
      })
    );
  };

  export const hideLineRenderer = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.showLineRenderer = false;
      })
    );
  };

  export const highlightStep = (stepId: string) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.highlightedStepSet.add(stepId);
      })
    );
  };

  export const unhighlightStep = (stepId: string) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.highlightedStepSet.delete(stepId);
      })
    );
  };

  export const setSurfaceCollisionDetected = (detected: boolean) => {
    useUIStore.setState({ surfaceCollisionDetected: detected });
  };

  export const setSurfaceCollisionEnabled = (enabled: boolean) => {
    useUIStore.setState({ enableCollisionDetection: enabled });
  };

  export const unhighlightAllSteps = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.highlightedStepSet.clear();
      })
    );
  };

  export const centerCameraOnSelection = (): void => {
    const selectionBounds = useUIStore.getState().selectionBounds;
    const selectionCenter = selectionBounds.getCenter(new Vector3());
    setCameraTarget(selectionCenter);
  };

  export const setCameraTarget = (target: Vector3): void => {
    const {camera, cameraControls} = useCanvasStore.getState();
    if (cameraControls && camera) {
      cameraControls.setTarget(target, camera);
    }
  };

  export const cacheCameraTransform = () => {
    const camera = useCanvasStore.getState().camera;
    if (camera) {
      const rotation = new Quaternion();
      rotation.setFromEuler(camera.rotation);
      useUIStore.setState(
        produce<UI>((state) => {
          state.cameraTransformCache = {
            position: {
              x: camera.position.x,
              y: camera.position.y,
              z: camera.position.z,
            },
            rotation: {
              x: rotation.x,
              y: rotation.y,
              z: rotation.z,
              w: rotation.w,
            },
          };
          state.cameraZoomCache = camera.zoom;
        })
      );
    }
  };

  export const restoreCameraTransform = () => {
    const camera = useCanvasStore.getState().camera;
    const cachedTransform = useUIStore.getState().cameraTransformCache;
    const cachedZoom = useUIStore.getState().cameraZoomCache;
    if (camera && cachedTransform && cachedZoom !== undefined) {
      CanvasController.setCameraTransform(cachedTransform);
      CanvasController.setCameraZoom(cachedZoom);
    }
  };

  export const setTransformAction = (action: 'rotate' | 'translate') => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.transformGizmo.action = action;
      })
    );
  };

  export const setDefaultTransform = (transform: Transform) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.defaultCameraSettings.transform = { ...transform };
      })
    );
  };

  export const setDefaultZoomAndDistance = (zoom: number, distance: number) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.defaultCameraSettings.distance = distance;
        state.defaultCameraSettings.zoom = zoom;
      })
    );
  };

  export const setPreviewRatio = (ratio: AspectRatio) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.previewRatio = ratio;
      })
    );
  };

  export const selectLastStepGroup = () => {
    const lastStep = SequenceController.getLastStep();
    const lastStepGroup = SequenceController.getLastStepGroup();

    if (lastStepGroup) {
      SequenceController.setSelectedStepGroup(lastStepGroup.id);
      expandStepGroup(lastStepGroup.id);
    }
    if (lastStep) {
      selectStepById(lastStep.id);
      MachineController.selectStep(lastStep);
    }
  };

  export const setSelectionActive = (active: boolean) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.selectionActive = active;
      })
    );
  };

  export const setShowSidebarTextRenderer = (active: boolean) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.showSidebarTextRenderer = active;
      })
    );
  };

  export const setShowStepDetails = (open: boolean) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.showStepDetails = open;
      })
    );
  };

  export const toggleStepDetails = () => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.showStepDetails = !state.showStepDetails;
      })
    );
  };

  export const setCameraClipboard = (settings?: StepCameraSettingsDto) => {
    useUIStore.setState(
      produce<UI>((state) => {
        state.cameraClipboard = sanitizeCameraSettings(settings);
      })
    );
  };

  export const isAnimating = () => {
    return useUIStore.getState().isAnimating;
  };

  export const reset = () => {
    useUIStore.getState().reset();
  };
}
