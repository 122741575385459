import { useModelStore, useSettingsStore, useUIStore } from '@assemblio/frontend/stores';
import { shallow } from 'zustand/shallow';
import { CameraControls } from './CameraControls';
import { useFramer } from './Framer';
import { Grid } from './Grid';
import { LineRenderer } from './LineRenderer';
import { ImperativeModel } from './Model/ImperativeModel';
import { ObjectControls } from './ObjectControls';
import { Surface } from './Surface';
import { Box3 } from 'three';
import { useThree } from '@react-three/fiber';
import { useEffect, useMemo } from 'react';

interface Props {
  onCameraChange?: () => void;
}

export const CanvasElements = ({ onCameraChange }: Props) => {
  const model = useModelStore((state) => state.model);
  const { isGridEnabled, lineWidth, lineColor } = useSettingsStore(
    (state) => ({
      isGridEnabled: state.viewport.grid.enabled,
      lineWidth: state.viewport.grid.thickness,
      lineColor: state.viewport.grid.color,
    }),
    shallow
  );

  const extent = useMemo(() => {
    const bounds = new Box3().setFromObject(model.scene);
    return bounds.max.length() * 3; // Three times the max extent of the model, to allow for room to move things
  }, [model]);

  useFramer();
  const cameraControlsActive = useUIStore((state) => state.cameraControlsActive);
  const { gl } = useThree();

  useEffect(() => {
    const canvas = gl.domElement;

    // Prevent context menu on right click
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };

    canvas.addEventListener('contextmenu', handleContextMenu);

    return () => {
      canvas.removeEventListener('contextmenu', handleContextMenu);
    };
  }, [gl]);

  return (
    <>
      <directionalLight
        position={[0, 10, 0]}
        castShadow
        shadow-mapSize={[2048, 2048]}
        intensity={1.5}
        shadow-camera-near={1}
        shadow-camera-far={20}
        shadow-camera-left={-extent}
        shadow-camera-right={extent}
        shadow-camera-top={extent}
        shadow-camera-bottom={-extent}
      />
      <ambientLight intensity={2} color={0xffffff} />
      {isGridEnabled && <Grid radius={20} spacing={1} lineWidth={lineWidth} lineColor={lineColor} />}
      {model ? <ImperativeModel gltf={model} /> : null}

      <CameraControls active={cameraControlsActive} onChange={onCameraChange} />
      <ObjectControls />
      <LineRenderer />
      <Surface extent={extent} />
    </>
  );
};
