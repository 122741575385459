import { Grid, Stack } from '@mantine/core';
import { ReactElement, ReactNode } from 'react';
import { GridTitle } from './components/GridTitle';
import { EmptyView } from '../../components/EmptyView/EmptyView';
import { StyleProp } from '@mantine/core/lib/core';
import { ColSpan } from '@mantine/core/lib/components/Grid/GridCol/GridCol';
import { BaseCardSkeleton } from './components/BaseCardSkeleton';

interface ExplorerGridProps {
  items: ReactElement[];
  isLoading?: boolean;
  loadingPlaceholder?: ReactNode;
  title?: string;
  itemsPerRow?: number;
  showEmptyView?: boolean;
}

const SKELETON_ITEM_COUNT = 5;

export const ExplorerGrid = ({
  items,
  isLoading = false,
  title,
  itemsPerRow = 4,
  showEmptyView = true,
  loadingPlaceholder = <BaseCardSkeleton />,
}: ExplorerGridProps) => {
  const isEmpty = !isLoading && items.length === 0;

  const responsive: StyleProp<ColSpan> = {
    base: 12 / (itemsPerRow - 2),
    md: 12 / (itemsPerRow - 1),
    lg: 12 / itemsPerRow,
    xl: 12 / (itemsPerRow + 1),
  };

  if (isEmpty && !showEmptyView) return null;

  return isEmpty && showEmptyView ? (
    <EmptyView />
  ) : (
    <Stack gap={'lg'}>
      {title && !isEmpty && <GridTitle isLoading={isLoading} title={title} count={items.length} />}
      <Grid gutter={'xl'} px={'xl'}>
        {isLoading
          ? [...Array(SKELETON_ITEM_COUNT)].map((_, index) => (
              <Grid.Col span={responsive} key={`explorerGrid-skeleton-${index}`}>
                {loadingPlaceholder}
              </Grid.Col>
            ))
          : items.map((item) => (
              <Grid.Col span={responsive} key={`explorerGrid-${item.props.item.id}`}>
                {item}
              </Grid.Col>
            ))}
      </Grid>
    </Stack>
  );
};
