import { useEffect, useState } from 'react';
import { useEventStore } from '@assemblio/frontend/stores';

interface JobUpdateProps {
  jobId: string;
  onJobCompletion: () => void;
}
export const useJobUpdates = ({ jobId, onJobCompletion }: JobUpdateProps) => {
  const socket = useEventStore((state) => state.socket);

  const [progress, setProgress] = useState<number | undefined>(undefined);

  useEffect(() => {
    const handleUpdate = (event: { jobId: string; progress?: number }) => {
      if (event.jobId !== jobId) return;
      if (typeof event.progress === 'undefined') {
        onJobCompletion();
      } else {
        setProgress(event.progress * 100);
      }
    };

    socket.on('product-job-update', handleUpdate);
    return () => {
      socket.off('product-job-update', handleUpdate);
    };
  }, [jobId]);

  return [progress];
};
