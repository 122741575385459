import { useQueryClient } from '@tanstack/react-query';
import { ProjectFoldersDto } from '@assemblio/shared/dtos';
import { useParams } from 'react-router-dom';

type ExplorerRoutingParams = {
  folderId?: string;
  projectId?: string;
  productId?: string;
  resourceId?: string;
};

export const useExplorerRouteParams = () => {
  const queryClient = useQueryClient();

  const rootFolder = queryClient.getQueryData<ProjectFoldersDto>(['rootFolder']);

  const { folderId, projectId, productId, resourceId } = useParams<ExplorerRoutingParams>() as ExplorerRoutingParams;

  return {
    folderId: folderId ?? rootFolder?.id,
    rootFolderId: rootFolder?.id,
    projectId,
    productId,
    resourceId,
  };
};
